import React, { Component } from "react";
import NewHeader from "./static/newHeader";
import { connect } from "react-redux";
import { getAllEvents, getEvent } from "./../../adminPanel/networkRequests/requests/event";
import { Modal } from "react-responsive-modal";
import moment from "moment";
import { toast } from "react-toastify";
import { AddPackageWorkshop, EventReservation } from "../../NetworkRequest/requests/event";
import { USER_TYPE } from "../Constants";
import { BASE_URL, ROZER_PAY_KEY } from "../../constant";
import LoginPopUp from "./loginPopUp";
// import { addMentoringSession } from "../../NetworkRequest/requests/package";
// import Layout from "../layout/default";

function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

const query = (search) => {
  var q = {};
  if (search !== "") {
    search
      .split("?")[1]
      .split("&")
      .forEach(function (i) {
        q[i.split("=")[0]] = i.split("=")[1];
      });
  }
  return q;
};

class EventDetail extends Component {
  userType = localStorage.getItem(USER_TYPE);
  pathname = this.props.location.pathname;
  userData = JSON.parse(localStorage.getItem("userData")) || {};
  packageId = query(this.props.location.search).packageId;
  // {packageId} = this.props.location.search
  // data = this.props.data;

  constructor(props) {
    super(props);
  }

  state = {
    event: "",
    events: [],
    infoModal: false,
    questionModal: false,
    answerOptionId: "",
    questionIndex: "",
    index: 0,
    response: [],
    name: this.userData.name || "",
    email: this.userData.email || "",
    phoneNumber: this.userData.phoneNumber ? this.userData.phoneNumber.toString() : "",
    question: "",
    answer: "",
    loginRequired: false,
    sameDate: false,
  };

  componentDidUpdate(prevState) {
    console.log("event111", this.packageId);
    if (prevState.data.name !== this.props.data.name) {
      this.setState({
        name: this.props.data.name,
        email: this.props.data.email,
        phoneNumber: this.props.data.phoneNumber || "",
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    console.log("next props is called", nextProps);
    if (nextProps.data) {
      this.userData = nextProps.data;
    }
  }

  _getEvent = async () => {
    try {
      console.log(this.userData, this.userType);
      const res = await getEvent(
        this.userData._id
          ? `${this.props.match.params.eventId}&user=${this.userData._id}&userType=${
              this.userType ? this.userType.toUpperCase() : "MENTEE"
            }`
          : this.props.match.params.eventId
      );
      this.setState({
        event: res.data,
        questionIndex: res.data.questionnaire.length - 1,
      });
    } catch (e) {
      console.log(e);
    }
  };

  componentDidMount() {
    console.log("props is", this.props);
    //   console.log("userType", this.context[0]);
    console.log("userData is", this.props.data, "+", this.userData, "end");
    window.scroll(0, 0);
    this._getEvent();
    this._events();
  }

  _events = async () => {
    try {
      const res = await getAllEvents(this.userData._id ? `?user=${this.userData._id}&userType=${this.userType.toUpperCase()}` : "");
      if (res.statusCode === 200) {
        
        this.setState({
          events: res.data,
          sameDate: res.data.startTime == res.data.endTime
        });
      }
    } catch (e) {
      console.log(e);
    }
  };
  handleTextChange = (e) => {
    console.log(e.target.name);
    this.setState({ [e.target.name]: e.target.value });
  };

  // componentDidUpdate(prevState) {
  //   if (prevState.userData !== this.props.userData) {
  //     this._events();
  //   }
  // }

  questionSubmit = () => {
    let { question, answer, index, questionIndex } = this.state;
    let response = [...this.state.response];
    // if (response.length < index + 1) {
    // response[response.length] = { question, answer };
    // } else {
    if (answer === "") return;
    response[index] = { question, answer };
    // }

    this.setState({ response });
  };
  openModal = () => {
    this.setState({ infoModal: true });
  };
  closeModal = () => {
    this.setState({ infoModal: false });
  };

  validateInfo = () => {
    let validate = true;
    if (this.state.name.trim() === "") {
      validate = false;
      toast.error("name should not be empty");
    }

    if (this.state.email.trim() === "") {
      validate = false;
      toast.error("Email should not be empty");
    } else {
      if (!validateEmail(this.state.email)) {
        validate = false;
        toast.error("Invalid Email");
      }
    }
    console.log(this.state.phoneNumber, "phoneNumber is");
    if (this.state.phoneNumber.length < 8 || this.state.phoneNumber.length > 15) {
      validate = false;
      toast.error("Please Enter valid mobile number");
    }
    return validate;
  };

  continueClick = (e) => {
    e.preventDefault();
    if (this.validateInfo()) {
      this.closeModal();
      if (this.state.event.questionnaire.length > 0) {
        this.openQuestionModal();
      } else {
        this.paidEventSubmit(e);
        console.log("no question model available");
      }
    }
    //this.validateInfo();
  };

  paidEventSubmit = async (e) => {
    console.log("paid event is called");
    if (e) {
      e.preventDefault();
    }
    if (this.packageId) {
      AddPackageWorkshop({
        event: this.state.event._id,
        attendeeName: this.state.name,
        attendeePhone: this.state.phoneNumber,
        attendeeEmail: this.state.email,
        questionnaireAnswered: this.state.event.questionnaire.map((question) => {
          return {
            correctAnswer: question.correctAnswer,
            options: question.options.map((x) => {
              return { answerOptions: x.answerOptions };
            }),
            question: question.question,
          };
        }),
        packagePurchaseId: this.packageId,
      }).then(res => {
        if(res.statusCode === 400) {
          toast.info(res.customMessage)
        } else {
          // window.location.reload();
          toast.success("Reservation successfully added ");
        }
        // console.log(res);
        // if (res.id) {
        //   this.startPayment(res);
        // } else {
        //    window.location.reload();
        //   toast.success("Reservation successfully added ");
        // }
      })

    } else {
      EventReservation({
        event: this.state.event._id,
        attendeeName: this.state.name,
        attendeePhone: this.state.phoneNumber,
        attendeeEmail: this.state.email,
        questionnaireAnswered: this.state.event.questionnaire.map((question) => {
          return {
            correctAnswer: question.correctAnswer,
            options: question.options.map((x) => {
              return { answerOptions: x.answerOptions };
            }),
            question: question.question,
          };
        }),
      }).then((res) => {
        this.setState({ questionModal: false });
        if (res.id) {
          localStorage.setItem("from", this.pathname);
          this.startPayment(res);
        } else {
          window.location.reload();
          toast.success("Reservation successfully added ");
        }
      });
    }
  };

  startPayment = (res) => {
    var options = {
      key: ROZER_PAY_KEY,
      amount: res.amount * 100, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      currency: "INR",
      prefill: {
        name: this.context.name,
        email: this.context.email,
        contact: this.context.phoneNumber,
      },
      order_id: res.id,
      callback_url: `${BASE_URL}${this.userType || "mentee"}/paymentSuccess`,
      theme: {
        color: "#3399cc",
      },
      // redirect: true
    };
    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  };

  openQuestionModal = () => {
    this.setState({ questionModal: true });
  };
  closeQuestionModal = () => {
    console.log("close clicked 1");
    this.setState({ questionModal: false });
  };

  onSuccessLogin = async () => {
    await this._getEvent();
    if (!this.state.isPurchased) {
      this.setState({ infoModal: true, loginRequired: false });
    }
  };

  render() {
    const bg = {
      modal: { borderRadius: 16 },
      overlay: {},
    };
    let { event, questionIndex, index } = this.state;
    console.log("states", this.state);

    // console.log("ara", event.startTime.toLocaleTimeString())

    return (
      <div className="wrapper container-width" style={{ paddingTop: "60px" }}>
        <LoginPopUp
          open={this.state.loginRequired}
          onClose={(e) => this.setState({ loginRequired: false })}
          googleSuccess={this.onSuccessLogin}
          routeName={this.pathname}
          onrrror={() => {
            this.setState({ loginRequired: false });
          }}
        />

        {/* header wrap */}
        <NewHeader props={this.props} />
        {/* header close */}



        <section className="sh-section sh-section__banner sh-event__details-banner" style={{ backgroundImage: "url(./images/home/slider-background.jpg)" }}>
          <div className="sh-section__inwrap">
            <div className="container">
              <div className="row">
                <div className="col-xl-8 mt-4 mt-xl-0">
                  <div className="sh-event__details-image">
                    <img className="img-fluid" src={event.image} alt={event.name + " | Shevolve Event"}
 />
                  </div>
                </div>
                <div className="col-xl-4">
                  <div className="sh-event__informations-wrap sh-card mt-4 mt-xl-0">
                    <div className="sh-event__informations-title">Event Information</div>
                    <div className="sh-event__information">
                      <div className="sh-event__information-label">Date</div>
                      <div className="sh-event__information-value sh-text">{moment(event.startTime).format("ll")}</div>
                      { this.state.sameDate == false &&
                      <div className="sh-event__information-value sh-text">{ moment(event.endTime).format("ll") }</div> }
                    </div>
                    <div className="sh-event__information">
                      <div className="sh-event__information-label">Event Type</div>
                      <div className="sh-event__information-value sh-text">{event.eventType}</div>
                    </div>
                    {/* {event.venue && 
                      <div className="sh-event__information">
                        <div className="sh-event__information-label">Venue Address</div>
                        <div className="sh-event__information-value sh-text">{event.venue}</div>
                      </div>
                    } */}

                    {/* {
                      event.sub_events && event.sub_events.map((sub_event, i) => (
                        <div className="sh-event__informations" key={i}>
                          <div className="sh-event__informations-subtitle">Event {i+1}</div>
                          { this.state.sameDate == false &&
                            <div className="sh-event__information">
                              <div className="sh-event__information-label">Date</div>
                              <div className="sh-event__information-value sh-text">{moment(sub_event.subevent_startTime).format("ll")}</div>                            
                              <div className="sh-event__information-value sh-text">{ moment(sub_event.subevent_endTime).format("ll") }</div>
                            </div>
                           }
                          <div className="sh-event__information">
                            <div className="sh-event__information-label">Event Starts At</div>
                            <div className="sh-event__information-value sh-text">{moment(sub_event.subevent_startTime).format("LT")} - {moment(sub_event.subevent_endTime).format("LT")}</div>
                          </div>
                          <div className="sh-event__information">
                            <div className="sh-event__information-label">Event Topic</div>
                            <div className="sh-event__information-value sh-text">{sub_event.subevent_title}</div>
                          </div>
                        </div>
                      ))
                    } */}
                  </div>
                  
                  <a
                      href="javascript:void(0);"
                      className="sh-button sh-button__no-radius w-100"
                      onClick={() => {
                        console.log(this.props.userData, "data is");
                        if (event.isSoldOut || event.isExpired) {
                          // toast.error('Event sold out git')
                        } else {
                          if (this.props.data._id) {
                            if (event.isPurchased) {
                              
                            } else {
                              this.setState({ infoModal: true });
                            }
                          } else {
                            this.setState({ loginRequired: true });
                            // localStorage.setItem('from', this.pathname)
                            // this.props.history.push({
                            //     pathname: '/login',
                            // })
                          }
                        }
                      }}
                    >
                      {event.isExpired ? "Expired" : event.isSoldOut ? "Sold out " : event.isPurchased ? "Attending" : "Reserve Now"}
                    </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="sh-section">
          <div className="sh-section__inwrap">
            <div className="container">
              <div className="col-md-8">
                <div className="sh-section__title-wrap">
                  <h1 className="sh-title sh-title__underline sh-event__details-title">{event.name}</h1>
                </div>
                <p className="sh-text sh-event__details-para" dangerouslySetInnerHTML={{ __html: event.description }} />
              </div>
            </div>
          </div>
        </section>

        <section className="sh-section pt-0">
          <div className="sh-section__inwrap">
            <div className="container">
              
            {
              event.sub_events && event.sub_events.map((sub_event, i) => (
                  <div className="sh-event__speakers pt-4 mb-4">
                    <div className="sh-event__speakers-badge">Event {i+1}</div>
                    <div className="sh-event__speakers-content">
                      <div className="sh-event__speakers-title font-weight-bold mb-2">{sub_event.subevent_title}</div>
                      <p className="sh-text">{sub_event.subevent_desc}</p>

                      <div className="sh-event__information-value sh-text">{moment(sub_event.subevent_startTime).format("DD MMM YYYY, h:mm A") } - {moment(sub_event.subevent_endTime).format("DD MMM YYYY, h:mm A") }</div>

                      <div className="mt-4">
                      { sub_event.sub_events_speakers.length > 0 && 
                        <div className="sh-event__speakers-label font-weight-semibold mb-4">Meet the events speakers</div>
                      }
                        <div className="row">

                          { sub_event.sub_events_speakers && sub_event.sub_events_speakers.map((speakers, i) => (
                              <div className="col-md-4">
                                <div className="sh-event__speaker d-flex flex-wrap align-items-center">
                                  <div className="sh-event__speaker-img" style={{ backgroundImage: `url(${speakers.speaker_image})` }}></div>
                                  <div className="sh-event__speaker-details">
                                    <div className="sh-event__speaker-name font-weight-bold">{speakers.speaker_name}</div>
                                    <div className="sh-event__speaker-role">{speakers.speaker_desc}</div>
                                  </div>
                                </div>
                              </div>
                            ))
                          }
                        </div>
                      </div>
                    </div>
                  </div>
              ))
            }

            </div>
          </div>
        </section>

        
        <Modal open={this.state.infoModal && !this.state.event.isPurchased} onClose={this.closeModal} center styles={bg}>
          <div
            style={{ display: "flex" }}
            // className={"modal fade"}
            id="news-modal"
            tabIndex="-1"
            role="dialog"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-xl form-size my-0">
              <div className="modal-content form-content basic-modal-content email-modal-content">
                <div
                  //style={{borderRadius : 5}}
                  className="news-wrap"
                >
                  <div className="row no-gutters">
                    <div className="col-md-12 form-padlr">
                      <div className="form-block">
                        <div className="basic-detail-header">
                          <div className="flex-space">
                            {/* <a href="javascript:void(0);">
											<i className="fas fa-times" />
										</a> */}
                          </div>
                        </div>
                        <div className="row no-gutters">
                          <div className="col-md-12">
                            <div className="email-text-content detail-text-content">
                              <div className="row">
                                <h6 className="sec-title sh-para font-weight-bold mb-3 col-md-12">Basic Details</h6>
                                <div className="col-md-12">
                                  <form>
                                    <div className="form-group">
                                      <label htmlFor="exampleInputName">Name</label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="exampleInputName"
                                        // placeholder="John Doe"
                                        name="name"
                                        value={this.state.name}
                                        onChange={this.handleTextChange}
                                      />
                                    </div>
                                    <div className="form-group">
                                      <label htmlFor="exampleInputEmail1">Email</label>
                                      <input
                                        type="email"
                                        disabled={true}
                                        className="form-control"
                                        id="exampleInputEmail1"
                                        aria-describedby="emailHelp"
                                        // placeholder="dineshshnpl3213@gmail.com"
                                        name="email"
                                        value={this.state.email}
                                        onChange={this.handleTextChange}
                                      />
                                    </div>
                                    <div className="form-group">
                                      <label htmlFor="exampleInputPassword1">Phone Number</label>
                                      <input
                                        type="number"
                                        className="form-control"
                                        id="exampleInputNumber"
                                        // placeholder="92373246783"
                                        name="phoneNumber"
                                        value={this.state.phoneNumber}
                                        onChange={this.handleTextChange}
                                      />
                                    </div>
                                    <div className="btn-wrap">
                                      <button
                                        type="submit"
                                        className="sh-button sh-button__no-radius w-100"
                                        // data-dismiss="modal"
                                        onClick={this.continueClick}
                                      >
                                        {this.state.event.questionnaire && this.state.event.questionnaire.length > 0
                                          ? "Continue"
                                          : event.price === 0
                                            ? "Submit"
                                            : "Proceed to Payment"}
                                      </button>
                                    </div>
                                  </form>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
        <Modal open={this.state.questionModal} onClose={this.closeQuestionModal} center styles={bg}>
          <div
            style={{ display: "flex" }}
            // className={"modal fade"}
            id="news-modal"
            tabIndex="-1"
            role="dialog"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-xl form-size my-0">
              <div className="modal-content form-content basic-modal-content email-modal-content">
                <div
                  //style={{borderRadius : 5}}
                  className="news-wrap"
                >
                  <div className="row no-gutters">
                    <div className="col-md-12 form-padlr">
                      <div className="form-block">
                        <div className="basic-detail-header">
                          <div className="flex-space">
                            <div style={{ display: "flex", alignItems: "center" }}>
                              {index === 0 ? null : (
                                <i
                                  className="fas fa-arrow-left mr-2"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.setState({
                                      index: index - 1,
                                      question: "",
                                      answer: "",
                                    });
                                  }}
                                  style={{ color: "#FFF", cursor: "pointer" }}
                                />
                              )}

                            </div>

                            {/* <a href="javascript:void(0);">
											<i className="fas fa-times" />
										</a> */}
                          </div>
                        </div>
                        <div className="row no-gutters">
                          <div className="col-md-12">
                            <div className="email-text-content detail-text-content">
                              <div className="row align-items-center">
                              <h6 className="sec-title sh-para font-weight-bold col-md-12">Basic Details</h6>
                                {/*<div>*/}
                                {/*    {index === 0 ? null : (*/}
                                {/*        <button*/}
                                {/*            className="btn btn-gradient-primary btn-sm m-2"*/}

                                {/*        >*/}
                                {/*            back*/}
                                {/*        </button>*/}
                                {/*    )}*/}
                                {/*</div>*/}
                                <p style={{ color: "black" }}>
                                  {event && event.questionnaire.length > 0 && index <= questionIndex
                                    ? event.questionnaire[index].question
                                    : null}
                                </p>

                                <div className="col-md-12">
                                  <form onSubmit={this.paidEventSubmit}>
                                    {event && event.questionnaire.length > 0 && event.questionnaire[index].answerType !== "input" ? (
                                      event.questionnaire[index].options.map((o) => (
                                        <div className="form-group">
                                          <a
                                            className={
                                              this.state.event.questionnaire[index].correctAnswer === o.answerOptions
                                                ? "inout-box-field active"
                                                : "inout-box-field"
                                            }
                                            href="javascript:void(0);"
                                            onClick={() => {
                                              const questionnaire = [...this.state.event.questionnaire];
                                              questionnaire[index].correctAnswer = o.answerOptions;
                                              const event = {
                                                ...this.state.event,
                                                questionnaire,
                                              };
                                              this.setState({ event });
                                            }}
                                          >
                                            {" "}
                                            <span className="title">{o.answerOptions}</span>
                                            <i class="far fa-check-circle " />
                                          </a>
                                        </div>
                                      ))
                                    ) : (
                                      <div>
                                        <textarea
                                          type="text"
                                          className="form-control"
                                          id="exampleInputName"
                                          placeholder="Answer"
                                          name="answer"
                                          onChange={(e) => {
                                            const questionnaire = [...this.state.event.questionnaire];
                                            questionnaire[index].correctAnswer = e.target.value;
                                            const event = {
                                              ...this.state.event,
                                              questionnaire,
                                            };
                                            this.setState({ event });
                                          }}
                                        />
                                      </div>
                                    )}

                                    {index === questionIndex ? (
                                      <div className="btn-wrap">
                                        <button
                                          type="submit"
                                          className="btn btn-gradient-primary w-100"
                                          disabled={this.state.event.questionnaire[index].correctAnswer === ""}
                                        >
                                          {event.price === 0 ? "Continue" : "Proceed to Payment"}
                                        </button>
                                      </div>
                                    ) : index < questionIndex ? (
                                      <div className="btn-wrap">
                                        <button
                                          type="submit"
                                          className="btn btn-gradient-primary w-100"
                                          disabled={this.state.event.questionnaire[index].correctAnswer === ""}
                                          // data-dismiss="modal"
                                          onClick={(e) => {
                                            console.log("close called");
                                            e.preventDefault();
                                            this.setState({ index: index + 1 });
                                            this.questionSubmit();
                                          }}
                                        >
                                          Next
                                        </button>
                                      </div>
                                    ) : null}
                                  </form>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
        {/* footer-wrap */}
        {/* <Footer props={this.props} /> */}
        {/* footer-wrap close*/}

        
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return { data: state.loginSignPopUp.userData };
};
export default connect(mapStateToProps)(EventDetail);
